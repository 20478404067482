<div (click)="helperService.onClickLostFocus()">
    @if(!pdfService.inExportMode){
        <div class="container-fluid" id="header" [style.background]="establishment?.establishmentBrandMainColor">
            <div class="brand-icon">
                <img src="{{logoReference}}" />
            </div>
            <div class="container">
                <div class="row justify-content-center header-section" id="header-content" [ngStyle]="{ 'color': textColor }">
                    <div class="col header-title">
                        <h1>Offerte bewerken</h1>
                        <span>{{currentYear}} - Alle rechten voorbehouden</span>
                    </div>
                </div>
            </div>
            <div class="header-actions hide-on-mobile">
                <button [disabled]="!loaded" (click)="showContext = !showContext" [ngClass]="showContext ? 'btn btn-success active-button' : 'btn btn-secondary'" id="show-context"><i class="bi bi-info-circle"></i> Hulp</button>
                <button [disabled]="!loaded" (click)="expandOrCollapseAll()" class="btn btn-secondary" id="expand-or-collpase-all"><i class="bi bi-chevron-bar-expand"></i>
                    @if(expandAccordion){
                        <span>Stappen uitklappen</span>
                    } @else {
                        <span>Stappen inklappen</span>
                    }
                </button>
                <button [disabled]="!loaded" (click)="pdfService.exportTender()" class="btn btn-secondary" id="generate"><i class="bi bi-file-earmark-text"></i> Exporteren</button>
                @if(!isViewer){
                    <button [disabled]="!loaded || isProcessingSavingTender" (click)="saveTender()" type="button" class="btn btn-secondary" id="save-tender-values">
                        @if(isProcessingSavingTender){
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        }
                    <i class="bi bi-save text-right"></i>Opslaan</button>
                }
            </div>
            <div class="mobile-actions">
                <button (click)="pdfService.exportTender()" class="btn btn-secondary" id="generate"><i class="bi bi-file-earmark-text"></i> Exporteren</button>
            </div>
        </div>
    }
    @if(!tender || !establishment){
        <div class="error-warning">
            @if(loaded){
                @if(tenderIsMissing){
                    <div class="alert alert-warning" role="alert">
                        <strong>Whoops!</strong> De opgevraagde offerte is niet gevonden. Weet u zeker dat deze offerte nog bestaat?
                    </div>
                } @else {
                    <div class="alert alert-danger" role="alert">
                        <strong>Whoops!</strong> Er is een fout opgetreden tijdens het laden van deze pagina... Probeer het later opnieuw.
                    </div>
                }
            } @else {
                <div class="loading-section">
                    <span class="loader"></span>
                    <span class="loader-text">Offerte wordt geladen...</span>
                </div>
            }
        </div>
    }
    @if(tender && establishment && !pdfService.inExportMode){
        <div class="container tender-content">
            <div class="row">
                @if(successFeedback){
                    <div class="success-warning">
                        <div class="alert alert-success" role="alert">
                            <strong>Succes!</strong> {{successFeedback}}
                        </div>
                    </div>
                }
                @if(warningFeedback){
                    <div class="success-warning">
                        <div class="alert alert-warning" role="alert">
                            <strong>Let op!</strong> {{warningFeedback}}
                        </div>
                    </div>
                }
            </div>
            <div class="row">
                <div class="col">
                    <div class="accordion" id="accordion">
                        <div class="card accordion-item">
                            <div class="card-header" id="headingMetaData">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMetaData" aria-expanded="true" aria-controls="collapseMetaData">
                                        Offerte gegevens
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseMetaData" class="collapse show" aria-labelledby="headingMetaData" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Onderstaand de meta-data die hoort bij deze offerte. Allereerst de naam en vervaldatum, deze invoervelden zijn verplicht. Door op de <strong>Grijze knop</strong> naast het tekstveld te drukken veranderd de stand van handmatige invoer naar automatische invoer en vice versa.
                                            Daaronder staan de huidige status van de offerte en of er een aanbetaling is gedaan. Is het veld voor de aanbetaling leeg dan betekend dat dat er geen aanbetaling is gedaan. Indien er <strong>0 euro</strong> wordt ingevoerd betekend dit dat er geen aanbetaling nodig is. Alle andere bedragen beteken dat er is aanbetaald.
                                        </span>
                                    </div>
                                }
                                <div class="card-body">
                                    <app-tender-meta-data [tenderTotal]="tenderTotal" [establishement]="establishment" [tender]="tender"></app-tender-meta-data>
                                </div>
                            </div>
                        </div>
                        @if(tender.guestValues){
                            <div class="card accordion-item">
                                <div class="card-header" id="headingGuestValues">
                                    <h5 class="mb-0 header-actions">
                                        <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuestValues" aria-expanded="true" aria-controls="collapseGuestValues">
                                            Gastgegevens
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseGuestValues" class="collapse show" aria-labelledby="headingGuestValues" data-parent="#accordion">
                                    @if(showContext){
                                        <div class="card-body context">
                                            <span class="context-header">Context:</span>
                                            <span>
                                                Gebruik onderstaand component om alle gegevens die bekend zijn over de gast vast te leggen. Zoek naar bestaande gasten door te zoeken op e-mailadres. Daarnaast kunnen adresgegevens worden toegevoegd om het factureren achteraf te vergemakkelijken.
                                            </span>
                                        </div>
                                    }
                                    <div class="card-body">
                                        <app-guest-values [establishment]="establishment" [tender]="tender"></app-guest-values>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="card accordion-item">
                            <div class="card-header" id="headingInvoiceValues">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInvoiceValues" aria-expanded="true" aria-controls="collapseInvoiceValues">
                                        Factuurgegevens <span class="badge badge-info new">Nieuw</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseInvoiceValues" class="collapse show" aria-labelledby="headingInvoiceValues" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>
                                            Gebruik onderstaand component om alle gegevens omtrent facturering vast te leggen. Deze kan later worden gebruikt om een factuur op te kunnen stellen waarbij direct alle gegevens voorhanden zijn.
                                        </span>
                                    </div>
                                }
                                <div class="card-body">
                                    <app-invoice-values [establishment]="establishment" [tender]="tender"></app-invoice-values>
                                </div>
                            </div>
                        </div>
                        @if(tender.partyValues){
                            <div class="card accordion-item">
                                <div class="card-header" id="headingPartyValues">
                                    <h5 class="mb-0 header-actions">
                                        <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePartyValues" aria-expanded="true" aria-controls="collapsePartyValues">
                                    Gelegenheidsgegevens
                                </button>
                                    </h5>
                                </div>
                                <div id="collapsePartyValues" class="collapse show" aria-labelledby="headingPartyValues" data-parent="#accordion">
                                    <div class="card-body">
                                        <app-party-values [tender]="tender" [establishment]="establishment"></app-party-values>
                                    </div>
                                </div>
                            </div>
                        }
                        @if(tender.guestValues){
                            <div class="card accordion-item">
                                <div class="card-header" id="headingArrangementsValues">
                                    <h5 class="mb-0 header-actions">
                                        <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseArrangementValues" aria-expanded="true" aria-controls="collapseArrangementValues">
                                            Arrangementen
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseArrangementValues" class="collapse show" aria-labelledby="headingArrangementsValues" data-parent="#accordion">
                                    @if(showContext){
                                        <div class="card-body context">
                                            <span class="context-header">Context:</span>
                                            <span>Onderstaand allereerst de lijst met op dit moment gekoppelde arrangementen. Deze zijn zichtbaar voor de gast en worden meegenomen in de offerte. Uit de lijst met "Beschikbare Arrangementen" kunnen arrangementen worden toegevoegd aan de offerte
                                                door gebruik te maken van de <strong>groene +</strong>. Staat het gewenste arrangement er niet tussen dan kan er gebruik gemaakt worden van de <strong>grijze +</strong> om een custom arrangement toe te voegen.
                                            </span>
                                        </div>
                                    }
                                    <div class="card-body">
                                        <app-arrangement-values [tender]="tender" [establishment]="establishment"></app-arrangement-values>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="card accordion-item">
                            <div class="card-header" id="headingParticularities">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseParticularities" aria-expanded="true" aria-controls="collapseParticularities">
                                        Bijzonderheden
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseParticularities" class="collapse show" aria-labelledby="headingParticularities" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Onderstaand kunnen bijzonderheden worden toegevoegd aan de offerte. Deze opmerkingen zijn <strong>zichtbaar</strong> op de offerte voor de gast, maar worden ook getoond op de sheet en keuken sheet.
                                        </span>
                                    </div>
                                }
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="particularities">Bijzonderheden <span class="badge bg-warning text-dark">Zichtbaar voor gasten</span></label>
                                            <textarea class="form-control" maxlength="4000" id="particularities" rows="3" [(ngModel)]="tender.particularities"></textarea>
                                            <div class="char-counter">
                                                @if(tender.particularities){
                                                    <div class="length-warning">
                                                        @if(tender.particularities.length >= 4000){
                                                            <i class="bi bi-exclamation-triangle-fill"></i>
                                                            <small>Het maximaal aantal tekens is 4000</small>
                                                        }
                                                    </div>
                                                    <small [ngClass]="tender.particularities.length >= 4000 ? 'exceeded' : 'counter'">{{tender.particularities.length}}/4000</small>
                                                } @else {
                                                    <small>0/4000</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card accordion-item">
                            <div class="card-header" id="headingKitchenParticularities">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseKitchenParticularities" aria-expanded="true" aria-controls="collapseKitchenParticularities">
                                        Interne opmerkingen
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseKitchenParticularities" class="collapse show" aria-labelledby="headingKitchenParticularities" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Onderstaand kunnen interne opmerkingen worden toegevoegd aan de offerte. Deze opmerkingen zijn <strong>niet zichtbaar</strong> op de offerte voor de gast, maar worden wel getoond op de sheet en keuken sheet.
                                        </span>
                                    </div>
                                }
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="kitchenParticularities">Bijzonderheden <span class="badge bg-secondary">Niet zichtbaar voor gasten</span></label>
                                            <textarea class="form-control" maxlength="4000" id="kitchenParticularities" rows="3" [(ngModel)]="tender.kitchenParticularities"></textarea>
                                            <div class="char-counter">
                                                @if(tender.kitchenParticularities){
                                                    <div class="length-warning" >
                                                        @if(tender.kitchenParticularities.length >= 4000){
                                                            <i class="bi bi-exclamation-triangle-fill"></i>
                                                            <small>Het maximaal aantal tekens is 4000</small>
                                                        }
                                                    </div>
                                                    <small [ngClass]="tender.kitchenParticularities.length >= 4000 ? 'exceeded' : 'counter'">{{tender.kitchenParticularities.length}}/4000</small>
                                                } @else {
                                                    <small>0/4000</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card accordion-item">
                            <div class="card-header" id="headingTimeLine">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTimeLine" aria-expanded="true" aria-controls="collapseTimeLine">
                                       Tijdspad
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTimeLine" class="collapse show" aria-labelledby="headingTimeLine" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Het tijdspad kan gebruikt worden om het verloop van een evenement vast te leggen. Denk hierbij aan welke activiteiten er in welke ruimte plaats vinden op welk tijdstip, maar denk ook aan het vastleggen bijvoorbeeld de tijdstippen waarop een gang van een walking diner mee gaat.
                                            Het tijdspad wordt standaard niet getoond in de offerte tenzij het vinkje "Weergeven op offerte" is aangevinkt.
                                        </span>
                                    </div>
                                }
                                <app-timeline-values [tender]="tender"></app-timeline-values>
                            </div>
                        </div>
                        <div class="card accordion-item">
                            <div class="card-header" id="headingTasks">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTasks" aria-expanded="true" aria-controls="collapseTasks">
                                        Interne taken
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTasks" class="collapse show" aria-labelledby="headingTasks" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Aan deze offerte kunnen interne taken worden toegevoegd. Deze taken zijn zichtbaar in deze offerte maar ook in het dashboard. Onder "Snelle acties" zijn een aantal standaard taken zichtbaar. Door de knop "Toevoegen" te gebruiken zal een nieuwe taak worden toegevoegd aan de offerte.
                                        </span>
                                    </div>
                                }
                                @if(activePackage?.taskManagement){
                                    <app-manage-tasks [user]="user" [isViewer]="isViewer" [tender]="tender"></app-manage-tasks>
                                } @else {
                                    <div class="card-body">
                                        <div class="alert alert-info col" role="alert">
                                            Gebruik maken van alle voordelen van interne taken? <button class="btn btn-link upgrade-button" routerLink="/advantages">Upgrade dan uw licentie.</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="card accordion-item">
                            <div class="card-header" id="headingTenderLogbook">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTenderLogbook" aria-expanded="true" aria-controls="collapseTenderLogbook">
                                       Logboek
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTenderLogbook" class="collapse show" aria-labelledby="headingTenderLogbook" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Het logboek kan gebruikt worden om wijzigingen in de offerte bij te houden of om gemaakte afspraken of aanpassingen te kunnen vastleggen. Dit onderdeel is niet zichtbaar voor gasten.
                                        </span>
                                    </div>
                                }

                                @if(activePackage?.tenderLogbook){
                                    <app-tender-logbook [tender]="tender"></app-tender-logbook>
                                } @else {
                                    <div class="alert alert-info upgrade-package" role="alert">
                                        Gebruik maken van deze functionaliteit? Upgrade naar <strong>"Premium" of "All-Access"</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="card accordion-item">
                            <div class="card-header" id="headingSummary">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSummary" aria-expanded="true" aria-controls="collapseSummary">
                                        Samenvatting
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseSummary" class="collapse show" aria-labelledby="headingSummary" data-parent="#accordion">
                                @if(showContext){
                                    <div class="card-body context">
                                        <span class="context-header">Context:</span>
                                        <span>Onderstaand de samenvatting van de huidige offerte. Dit komt overeen met wat de gast ziet op de uiteindelijke offerte. Controlleer hier of alle prijzen, opmerkingen en aantallen juist zijn ingevoerd.
                                        </span>
                                    </div>
                                }
                                <div class="card-body">
                                    <div class="row">
                                        <app-tender-summary [tenderTotal]="tenderTotal" [tender]="tender"></app-tender-summary>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card accordion-item">
                            <div class="card-header" id="headingPriceStructure">
                                <h5 class="mb-0 header-actions">
                                    <button class="btn btn-link group-header" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePriceStructure" aria-expanded="true" aria-controls="collapsePriceStructure">
                                        Prijsopbouw
                                    </button>
                                </h5>
                            </div>
                            <div id="collapsePriceStructure" class="collapse show" aria-labelledby="headingPriceStructure" data-parent="#accordion">
                                <div class="card-body context" *ngIf="showContext">
                                    <span class="context-header">Context:</span>
                                    <span>In dit component kan bepaald worden welke verdeling er wordt gemaakt tussen de hoog-/laag-afsplitsing met betrekking tot de BTW afdracht op drankarrangementen.
                                    </span>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <app-price-structure [tender]="tender"></app-price-structure>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="tender-bottom-actions">
                        <div class="actions-wrapper">
                            @if(missingCopyToDate && activePackage?.duplicateTenders){
                                <span class="missing-warning">Kies een datum om naar te kopi&euml;ren</span>
                            }

                            @if(!activePackage?.duplicateTenders){
                                <span class="using-duplicate">Gebruik maken van deze functionaliteit? Upgrade naar "All-Access"</span>
                                <button disabled type="button" class="btn btn-secondary" id="save-tender-values-as-copy-dummy"><i class="bi bi-front text-right"></i>Kopi&euml;ren naar datum</button>
                            } @else {
                                <div class="form-group">
                                    <input class="form-control" type="date" [(ngModel)]="copyToDate" [ngClass]="{'missing-date' : missingCopyToDate}" />
                                </div>
                                <button (click)="saveAsCopy()" type="button" class="btn btn-secondary" id="save-tender-values-as-copy"><i class="bi bi-front text-right"></i>Kopi&euml;ren naar datum</button>
                            }
                            <button (click)="saveTender()" type="button" class="btn btn-success" id="save-tender-values"><i class="bi bi-save text-right"></i>Opslaan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    <!-- <div class="side-options">
        <button (click)="saveTender()" type="button" class="btn btn-success" id="save-tender-values"><i class="bi bi-save text-right"></i>Opslaan</button>
        <button (click)="showContext = !showContext" [ngClass]="showContext ? 'btn btn-success' : 'btn btn-secondary'" id="show-context"><i class="bi bi-info-circle"></i> Hulp</button>
        <button (click)="expandOrCollapseAll()" class="btn btn-secondary" id="expand-or-collpase-all"><i class="bi bi-chevron-bar-expand"></i><span *ngIf="expandAccordion">Stappen uitklappen</span><span *ngIf="!expandAccordion">Stappen inklappen</span></button>
    </div> -->
    @if(!pdfService.inExportMode){
        <div class="toast-section">
            <div id="test-toast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <i class="bi bi-check-circle toast-icon"></i>
                    <strong class="me-auto">{{feedbackHeader}}</strong>
                    <small>1 seconde geleden</small>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    {{successFeedback}}
                </div>
            </div>
        </div>
    }
    @if(pdfService.inExportMode && tender && establishment){
        <app-pdf-tender [activePackage]="activePackage" [textColor]="textColor" [logoReference]="logoReference" [tender]="tender" [establishment]="establishment"></app-pdf-tender>
    }
</div>
@if(showCopyPopUp){
    <div class="pop-over-wrapper">
        <div class="pop-over">
            <h2><i class="bi bi-copy"></i> Succesvol gekopie&euml;rd</h2>
            <span>De offerte is succesvol gekopie&euml;rd naar <strong>{{copyToDate | date: "dd-MM-yyyy"}}</strong>. Eventueel aanwezige taken zijn niet mee gekopie&euml;rd.</span>
            <br><br>
            <span>Wilt u verder in deze offerte of in de gekopie&euml;rde offerte?</span>
            <div class="pop-up-actions">
                <button class="btn btn-secondary" (click)="closePopUp(false)">Deze offerte bewerken</button>
                <button class="btn btn-secondary" (click)="closePopUp(true)">Gekopie&euml;rde offerte bewerken</button>
            </div>
        </div>
    </div>
}