import { Component, OnDestroy, OnInit } from '@angular/core';
import { Establishment } from 'src/app/shared/objects/establishment';
import { AuthService } from 'src/app/services/Auth/auth-service';
import { Tender } from 'src/app/shared/objects/tender';
import { TenderService } from 'src/app/services/Tender/tender.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfService } from 'src/app/services/PDF-service/pdf.service';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { VersionService } from 'src/app/services/Version/version.service';
import { ActivePackage } from 'src/app/shared/objects/activePackage';
import { Api } from 'src/app/helpers/api';
import { EstablishmentService } from 'src/app/services/Establishment/establishment.service';
import { Models } from 'appwrite';
import { UserPreferences } from 'src/app/shared/objects/userPreferences';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TenderMetaData } from 'src/app/shared/objects/tender-meta-data';
import { LogbookService } from 'src/app/services/Logbook/logbook.service';
import { HelperService } from 'src/app/services/Helper/helper.service';
import { FormsModule } from '@angular/forms';
import { TenderMetaDataComponent } from '../tender-components/tender-meta-data/tender-meta-data.component';
import { GuestValuesComponent } from '../tender-components/guest-values/guest-values.component';
import { PartyValuesComponent } from '../tender-components/party-values/party-values.component';
import { TimelineValuesComponent } from '../tender-components/timeline-values/timeline-values.component';
import { ManageTasksComponent } from '../tender-components/manage-tasks/manage-tasks.component';
import { TenderSummaryComponent } from '../tender-components/tender-summary/tender-summary.component';
import { PdfTenderComponent } from '../pdf-tender/pdf-tender.component';
import { ArrangementValuesComponent } from '../tender-components/arrangement-values/arrangement-values.component';
import { TenderLogbookComponent } from '../tender-components/tender-logbook/tender-logbook.component';
import { InvoiceValuesComponent } from '../tender-components/invoice-values/invoice-values.component';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, TenderMetaDataComponent, GuestValuesComponent, PartyValuesComponent, TimelineValuesComponent,
    ManageTasksComponent, TenderSummaryComponent, PdfTenderComponent, ArrangementValuesComponent, TenderLogbookComponent, InvoiceValuesComponent, DatePipe
  ],
  selector: 'app-edit-tender',
  templateUrl: './edit-tender.component.html',
  styleUrls: ['./edit-tender.component.scss']
})
export class EditTenderComponent implements OnInit, OnDestroy {

  user?: Partial<Models.User<UserPreferences>>;
  currentStep: number = 0;
  establishment?: Establishment;
  tenderTotal?: BehaviorSubject<number>;
  tender?: Tender;
  copyToDate?: Date;
  missingCopyToDate?: boolean;
  showCopyPopUp?: boolean;
  idOfDuplicatedTender?: string;
  successFeedback?: string;
  warningFeedback?: string;
  feedbackHeader?: string;
  routeId: string | null;
  fromSave: string | null;
  initialStatus?: string;
  isViewer: boolean = true;
  showContext: boolean = false;
  expandAccordion: boolean = false;
  activePackage?: ActivePackage;
  activeTeamId?: string;
  loaded: boolean = false;
  tenderIsMissing: boolean = false;
  currentYear?: string;
  logoReference?: URL;
  isProcessingSavingTender: boolean = false;
  textColor?: string;
  subscribtions: (Subscription | undefined)[] = [];

  constructor(private authService: AuthService,
    private tenderService: TenderService,
    private route: ActivatedRoute,
    public pdfService: PdfService,
    private communication: InternalCommunicationService,
    private versionService: VersionService,
    private establishmentService: EstablishmentService,
    private router: Router,
    private logbookService: LogbookService,
    public helperService: HelperService) {
    this.routeId = this.route.snapshot.paramMap.get('id');
    this.fromSave = this.route.snapshot.paramMap.get('fromSave');
    this.currentYear = new Date().getFullYear().toString();
    this.communication.hideTopBarNavigation?.next(false);
    this.tenderTotal = new BehaviorSubject<number>(0);
    if(communication.showSheet){
      this.pdfService.exportTender();
      communication.showSheet = false;
    }
  }

  async ngOnInit() {
    this.subscribtions.push(this.communication.userIsViewer?.subscribe(isViewer => {
      this.isViewer = isViewer;
    }));

    this.subscribtions.push(this.communication.activePackage?.subscribe(activePackage => {
      this.activePackage = activePackage;
    }));

    // Set unsavedChanges
    this.communication.unsavedChanges = true;

    // Get user to edit when variables changes
    this.subscribtions.push(this.authService.user.subscribe(async authUser => {
      if (authUser) {
        this.user = authUser;
  
        if (!this.user.prefs?.activeEstablishmentId) {
          this.loaded = true;
          return;
        }
  
        // Get Tender
        if (this.routeId != null) {
          this.tenderService.getTenderById(this.routeId).then(tender => {
            if (tender) {
  
              this.logbookService.getLogbookItemsOfTender(tender.$id).then(logbookItems => {
                tender!.logbookItems = logbookItems;
              });
                  
              // Check for upgrades
              if (this.versionService.checkForUpgrades(tender, this.activeTeamId!)) {
                this.successFeedback = `De offerte is succesvol geupgrade naar versie ${this.versionService.currentVersion}.`
                // Hide after 3 seconds
                setTimeout(() => { this.successFeedback = undefined }, 3000);
              }
  
              this.tender = tender;
              this.initialStatus = tender.tenderStatus;
              this.communication.shallowTender = structuredClone(this.tender);
              
              if (this.fromSave && this.tender) {
                this.successFeedback = "De offerte: " + this.tender.tenderName + " is succesvol opgeslagen."
                // Hide after 3 seconds
                setTimeout(() => { this.successFeedback = undefined }, 3000);
              }
  
              this.loaded = true;
            } else {
              this.loaded = true;
              this.tenderIsMissing = true;
              return;
            }
          }).catch(() => {
            this.loaded = true;
          })
        } else {
          // In this case there has occured an error
          this.loaded = true;
        }
        
        // Try get Establishment from CommunicationService --> Else load from database
        var loadedEstablishment = this.communication.activeEstablishment?.getValue();
        
        if(loadedEstablishment?.$id != this.user.prefs?.activeEstablishmentId) {
          loadedEstablishment = await this.establishmentService.getEstablishment(this.user.prefs?.activeEstablishmentId);
        }
  
        if (!loadedEstablishment) {
          this.loaded = true;
          return;
        }
  
        this.establishment = loadedEstablishment;
        this.subscribtions.push(this.communication.activeEstablishmentLogoReference.subscribe(logoReference => {
          this.logoReference = logoReference;
        }));
  
        // Set active teamId
        this.activeTeamId = this.communication.activeTeamId?.getValue();
  
        // Set active teamId
        if(!this.activeTeamId){
          Api.teams.list().then(teamsList => {
            var team = teamsList.teams.filter(t => t.name == this.establishment?.establishmentName);
            if (team?.length > 0) {
              this.activeTeamId = team[0].$id;
            }
          });
        }
  
        // Set TextColor of Header
        this.textColor = this.helperService.getTextColor(this.establishment?.establishmentBrandMainColor);
        
        // Set unsavedChanges
        this.communication.unsavedChanges = true;
      } else {
        this.loaded = true;
      }
    }))
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  expandOrCollapseAll(){
    this.helperService.expandOrCollapseAll(this.expandAccordion)
    this.expandAccordion = !this.expandAccordion;
  }

  /**
   * Save the current tender to a new date as a copy
   * @returns 
   */
  saveAsCopy(){
    // Check for correct permissions
    if(!this.activePackage?.duplicateTenders){
      return;
    }

    if(!this.copyToDate){
      this.missingCopyToDate = true;
      return;
    }

    this.missingCopyToDate = false;

    // Create new tender from current base
    if(this.tender && this.activeTeamId){
      // Override default meta-data
      var overrideMetaData: TenderMetaData = {
        createdBy: this.user?.name ?? "Unknown",
        createdDate: new Date(),
      }
      
      this.tenderService.createTender(structuredClone(this.tender), this.activeTeamId, this.copyToDate, overrideMetaData).then(tenderId => {
        if(tenderId){
          this.idOfDuplicatedTender = tenderId;
          this.showCopyPopUp = true;
        }
      });

    }
  }

  /**
   * This method will close the pop-up and open the copied tender if needed
   * @param openCopiedTender 
   */
  closePopUp(openCopiedTender: boolean){
    if(openCopiedTender && this.idOfDuplicatedTender){
      // Save tender to prevent from missing values 
      this.saveTender();
      
      var route = '/edit-tender/' + this.idOfDuplicatedTender;
      this.router.navigate([route]).then(() => {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
      })
    }

    this.showCopyPopUp = false;
  }

  /**
   * This method will save a tender to the firestore
   */
  async saveTender() {
    if (!this.tender) {
      return;
    }
    
    // Toast messages
    var toast = document.getElementById('test-toast');

    // Reset messages
    this.warningFeedback = undefined;
    this.successFeedback = undefined;

    var expirationInput = document.getElementById('tender-expirationDate');
    var nameInput = document.getElementById('tender-name');

    if (expirationInput && nameInput) {
      expirationInput.style.borderColor = "#ced4da";
      expirationInput.style.borderWidth = "1px";
      nameInput.style.borderColor = "#ced4da";
      nameInput.style.borderWidth = "1px";
    }
    // Check if tender name is filled
    if (!this.tender.tenderName || this.tender.tenderName == "" || this.tender.tenderName?.length < 2) {
      if (nameInput) {
        nameInput.style.borderColor = "#ff3333";
        nameInput.style.borderWidth = "2px";
      }
      this.warningFeedback = "Kies een naam van tenminste twee tekens voor de offerte alvorens deze kan worden opgeslagen."
      return;
    }

    if (!this.tender.expirationDate) {

      if (expirationInput) {
        expirationInput.style.borderColor = "#ff3333";
        expirationInput.style.borderWidth = "2px";
      }
      this.warningFeedback = "Kies een vervaldatum voor de offerte alvorens deze kan worden opgeslagen."
      return;
    }

    // Check if particularities are not containing more chars then accepted
    if (this.tender.particularities && this.tender.particularities.length > 4000) {
      this.warningFeedback = "Het veld Bijzonderheden mag maximaal 4000 karakters bevatten alvorens deze kan worden opgeslagen."
      return;
    }

    // Check if particularities are not containing more chars then accepted
    if (this.tender.kitchenParticularities && this.tender.kitchenParticularities.length > 4000) {
      this.warningFeedback = "Het veld Interne opmerkingen mag maximaal 4000 karakters bevatten alvorens deze kan worden opgeslagen."
      return;
    }

    this.isProcessingSavingTender = true;
    // Update LastUpdated State
    this.tender.lastModifiedBy = this.user?.name ?? "Unknown";
    this.tender.lastModifiedDate = new Date();

    // Check if status was updated
    if (this.initialStatus != this.tender.tenderStatus) {
      // Log status change
      this.tender.statusChangedBy = this.user?.name ?? "Unknown";
      this.tender.statusChangedDate = new Date();
      this.tender.statusChangedFromTo = `${this.initialStatus} -> ${this.tender.tenderStatus}`;
      this.initialStatus = this.tender.tenderStatus;
    }

    // TEMP CODE: New tenders should always have this value
    // Check if TenderNumber is present --> If not fill with correct value;
    if(!this.tender?.uniqueTenderNumber){
      this.tender.uniqueTenderNumber = await this.tenderService.getTenderNumber();
    }

    this.tenderService.updateTender(this.tender, this.activeTeamId!).then(result => {
      // Doc is saved so go to Edit page
      if (!this.tender || !result) {
        this.warningFeedback = "Het opslaan van de offerte is niet gelukt. Mogelijk bevat de offerte ongeldige gegevens"
        this.isProcessingSavingTender = false;
        // Hide after 3 seconds
        setTimeout(() => { this.warningFeedback = undefined }, 3000);
        return;
      }

      this.tenderService.getTenderById(this.tender.$id).then(tender => {
        this.tender = tender;

        // Add or replace tender in dashboard list
        if(tender){
          this.tenderService.addOrUpdateToCurrentTenderList(tender);

          this.logbookService.getLogbookItemsOfTender(tender.$id).then(logbookItems => {
            tender!.logbookItems = logbookItems;
          });  
        }

        // Give user feedback
        this.successFeedback = "De offerte: " + this.tender?.tenderName + " is succesvol opgeslagen."
        this.isProcessingSavingTender = false;
        if (toast) {
          toast.className = "toast fade show";
        }

        // Hide after 3 seconds
        setTimeout(() => {
          this.successFeedback = undefined;
          this.feedbackHeader = undefined;
          if (toast) {
            toast.className = "toast";
          }
        }, 5000);
      })
    }).catch(() => {
      this.warningFeedback = "Het opslaan van de offerte is niet gelukt. Mogelijk heeft u onvoldoende rechten."
      this.isProcessingSavingTender = false;
      // Hide after 3 seconds
      setTimeout(() => { this.warningFeedback = undefined }, 3000);
    });
  }
}
